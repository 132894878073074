<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
        <div class="img3_a" @click="gotoOfficialWebsite"></div>
      </div>
    </div>
  </div>
</template>

<script>
import productImg1 from "@/page/tamogiefectivo/components/img/5_1.png";
import productImg2 from "@/page/tamogiefectivo/components/img/55.png";
import productImg3 from "@/page/tamogiefectivo/components/img/5_3.png";
export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      productImg3: productImg3,
      altText: "图片",
    };
  },
  methods:{
    gotoOfficialWebsite() {
      window.open("https://www.kartermax.com", '_blank');
    },
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1,.img2,.img3 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}

.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  max-width: 100%;
}

.img3{
  position: relative;
}
.img3_a{
  position: absolute;
  top:53%;
  left: 15%;
  height: 80px;
  width: 310px;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}
</style>

<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <div class="img3-content">
          <img :src="importedImg3_1" :alt="altText" class="imgItem3_1 imgItem3" @click="gotoPDF1" />
          <img :src="importedImg3_2" :alt="altText" class="imgItem3_2 imgItem3" @click="gotoPDF2" />
          <img :src="importedImg3_3" :alt="altText" class="imgItem3_3 imgItem3" @click="gotoPDF3" />
        </div>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
        <div class="img4_a" @click="gotoOfficialWebsite"></div>
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <!-- <img :src="importedImg5" :alt="altText" class="imgItem5" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/tamogiefectivo/components/img/1_1.png";
import importedImg2 from "@/page/tamogiefectivo/components/img/1_2.png";
// import importedImg3 from "@/page/tamogiefectivo/components/img/1_3.png";
import importedImg4 from "@/page/tamogiefectivo/components/img/1_4.png";
import importedImg3_1 from "@/page/tamogiefectivo/components/img/1_3_1.png";
import importedImg3_2 from "@/page/tamogiefectivo/components/img/1_3_2.png";
import importedImg3_3 from "@/page/tamogiefectivo/components/img/1_3_3.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      // importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg3_1: importedImg3_1,
      importedImg3_2: importedImg3_2,
      importedImg3_3: importedImg3_3,
      // importedImg5: importedImg5,
      altText: "图片",
    };
  },
  methods: {
    gotoOfficialWebsite() {
      window.open("https://www.kartermax.com", '_blank');
    },
    gotoPDF1(){
      window.open('/Cualificacion de la empresa.pdf');
    },
    gotoPDF2(){
      window.open('/Licencia de empresa.pdf');
    },
    gotoPDF3(){
      window.open('/Autorizacion empresarial.pdf');
    }
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox1,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.imgBox2,
.imgBox3,
.imgBox4 {
  display: flex;
  justify-content: center;
  width: 100%;
}

img {
  display: block;
}

.img1 .img5 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
}

.img2,
.img3,
.img4 {
  margin-left: 45px;
  margin-right: 45px;
}

.imgItem1 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}

.imgItem2,
.imgItem3,
.imgItem4 {
  max-width: 100%;
}

.imgItem3 {
  /* margin-bottom: 40px; */
}

.imgItem4 {
  /* margin-bottom: 80px; */
}

.imgItem5 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}

.img2{
  padding: 0 240px;
}

.img2 {
  margin: 100px 0;
}
.img3-content {
  /* margin-bottom: 40px; */
  display: flex;
}
.imgItem3_1,
.imgItem3_2,
.imgItem3_3{
  width: 385px;
  cursor: pointer;
}
.img4{
  position: relative;
}
.img4_a{
  position: absolute;
  top:84%;
  left: 15%;
  height: 80px;
  width: 310px;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}
.img3{
  margin-top: 100px;
}
</style>

<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <!-- <img :src="importedImg2" :alt="altText" class="imgItem2" /> -->
      </div>
    </div>

    <!-- <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
      <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div> -->
    <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <!-- <img :src="importedImg6" :alt="altText" class="imgItem6" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
// import importedImg1 from "@/page/tamogiefectivo/components/img/4.png";
// import importedImg2 from "@/page/tamogiefectivo/components/img/444.png";
// import importedImg3 from "@/page/tamogiefectivo/components/img/4_3.png";
// import importedImg4 from "@/page/tamogiefectivo/components/img/4_4.png";
// import importedImg5 from "@/page/tamogiefectivo/components/img/4_5.png";
// import importedImg6 from "@/page/tamogiefectivo/components/img/44.png";

export default {
  data() {
    return {
      // importedImg1: importedImg1,
      // importedImg2: importedImg2,

      // importedImg6: importedImg6,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1,
.imgBox2,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox3,
.imgBox4,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f0f6fe;
}
img {
  display: block;
}
.img1,
.img2,
.img6 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}

.img3,
.img4,
.img5 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,
.imgItem6 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.imgItem2 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem3 {
  max-width: 100%;
  margin-bottom: 50px;
}
.imgItem4 {
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 50px;
}
.imgItem5 {
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 80px;
}
.imgItem6 {
  max-width: 100%;
}
</style>

<template>
  <nav class="navbar">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/tamogiefectivo/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">Tamogi, S.A. de C.V., Sofom, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Sobre nosotros</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Por qué elegirnos</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/center' }" class="Li3">
          <router-link to="/center" class="nav-link3">
            <span class="routeText3">Solución</span>
          </router-link>
        </li>
        <!-- <li
          :class="{ 'active-link': $route.path === '/questions' }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Solución</span>
          </router-link>
        </li> -->
        <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="lastLi"
        >
          <!-- <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de Privacidad</span>
          </router-link> -->
          <a href="https://temiw.tamogiefectivo.com/help/privacy_agreement" class="nav-link3" target="_blank">
            <span class="routeText4">Acuerdo de Privacidad</span>
          </a>
        </li>
      </ul>
    </div>
    <div style="height: 110px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: #fff;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 110px;
  padding: 0 10px;
  background-color: #F3F8FF;
}


.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 50px;
}

.title {
  width: 550px;
  height: 24px;
  font-size: 20px;
  font-family: Roboto Flex-SemiBold;
  /* font-weight: 500; */
  font-weight: 800;
  color: #000000;
  line-height: 23px;
  /* padding-left: 18px; */
  padding-left: 190px;
  /* border: 1px solid red; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: Roboto Flex-Medium;
  font-weight: 600;
  color: #9d9d9d;
  line-height: 29px;
}

.routeText1 {
  width: 85px;

  padding-left: 8px;
  padding-right: 8px;
}

.routeText2 {
  width: 148px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText3 {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText4 {
  width: 200px;
  padding-left: 16px;
  padding-right: 8px;
  text-align: center;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  height: 49px;
  font-weight: 500;
  line-height: 39px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  padding-top: 5px;
  padding-bottom: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 49px;
}
.Li1 {
  width: 180px;
}
.Li2 {
  width: 180px;
}
.Li3 {
  width: 150px;
}
.Li4 {
  width: 130px;
}
.lastLi {
  width: 220px;
  margin-right: 28px;
}
/* .active-link {
  background-size: 100% 100%;
} */
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-size: 18px;
  font-family: Inter-Extra Bold;
  font-weight: 600;
  color: #2d62f3;
}
.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  border-bottom: 4px solid #2d62f3; /* 初始时下划线可见 */
  border-radius: 20px;
  width: 50%; /* 调整下划线的长度 */
  transition: width 0s ease-in-out; /* 添加 transition 效果 */
  margin-top: 10px;
}
.active-link .routeText1::after{
  margin-left: 15px;
}
.active-link .routeText2::after {
  margin-left: 25px;
}
.active-link .routeText4::after {
  margin-left: 18px;
}
.active-link .routeText3::after{
  margin-left: 5px;
}
.active-link .routeText5::after {
  margin-left: 25px;
}
</style>